import React from 'react'
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
const About = () => {
  return (
    <>
 
    <Header/>

    <div>About</div>
    <Footer />
    </>
  )
}

export default About