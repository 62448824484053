


import React, { useState } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
const PasswordResetForm = () => {
  const { token } = useParams(); // Get the token from URL params
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const navigate=useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`https://api.nfcdesignstudio.com/API/v1/reset-password/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ password, confirmPassword })
      });

      if (response.ok) {
        alert('Password reset successfully.');
        setError('');
        navigate("/login"); 
        
      } else {
        const data = await response.json();
        alert(data.error || 'Failed to reset password. Please try again.');
        setSuccessMessage('');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      alert('Failed to reset password. Please try again.');
      setSuccessMessage('');
    }
  };

  return (
    <>
    <Header/>
    <Container className="mt-5">
      <div className="forgot-password-form shadow p-4 rounded">
        <h2 className="text-center mb-4">Reset Password</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="password" className='mb-3'>
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter new password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="confirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </Form.Group>

          {error && <Alert variant="danger">{error}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}

          <Button variant="primary" type="submit" className="w-100 mt-3">
            Reset Password
          </Button>
        </Form>
      </div>
    </Container>
    <Footer/>
    </>
  );
};

export default PasswordResetForm;
