// import React, { useRef, useState } from 'react';
// import QRCode from 'qrcode.react';

// const QRCodeWithLogo = ({ websiteurl, qrCodeSize, eMedhaLogo, logoSize }) => {
//   const canvasRef = useRef(null);

//   const handleSaveQRCode = async () => {
//     if (canvasRef.current) {
//       const dataURL = canvasRef.current.toDataURL('image/png');
//       try {
//         const response = await fetch('http://localhost:3000/api/save-qrcode', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({ image: dataURL }),
//         });
//         if (response.ok) {
//           console.log('QR Code saved successfully.');
//         } else {
//           console.error('Failed to save QR Code.');
//         }
//       } catch (error) {
//         console.error('Error saving QR Code:', error);
//       }
//     }
//   };

//   return (
//     <div>
//       <div
//         style={{ position: 'relative', width: qrCodeSize, height: qrCodeSize }}
//       >
//         <QRCode
//           value={websiteurl}
//           size={qrCodeSize}
//           bgColor="#ffffff"
//           fgColor="#000000"
//           level="H"
//           includeMargin={true}
//           renderAs="canvas"
//           ref={canvasRef}
//         />
//         {eMedhaLogo && (
//           <img
//             src={eMedhaLogo}
//             alt="Logo"
//             style={{
//               position: 'absolute',
//               top: '50%',
//               left: '50%',
//               transform: 'translate(-50%, -50%)',
//               width: logoSize,
//               height: logoSize,
//               borderRadius: '50%',
//               border: '  #ffffff',
//             }}
//           />
//         )}
//       </div>
//       <button onClick={handleSaveQRCode}>Save QR Code</button>
//     </div>
//   );
// };

// export default QRCodeWithLogo;

// import React, { useRef, useEffect, useState } from 'react';
// import QRCode from 'qrcode';

// const QRCodeWithLogo = ({ websiteurl, qrCodeSize, eMedhaLogo, logoSize }) => {
//   const canvasRef = useRef(null);
//   const [dataURL, setDataURL] = useState('');

//   useEffect(() => {
//     // Generate QR code using qrcode library
//     QRCode.toCanvas(
//       canvasRef.current,
//       websiteurl,
//       {
//         width: qrCodeSize,
//         margin: 0,
//         color: {
//           dark: '#000000',  // QR code color
//           light: '#ffffff'  // Background color
//         },
//         errorCorrectionLevel: 'H'
//       },
//       (err) => {
//         if (err) {
//           console.error('Error generating QR Code:', err);
//           return;
//         }

//         // Draw logo on top
//         const canvas = canvasRef.current;
//         const ctx = canvas.getContext('2d');

//         if (eMedhaLogo) {
//           const logo = new Image();
//           logo.src = eMedhaLogo;
//           logo.onload = () => {
//             ctx.drawImage(
//               logo,
//               (qrCodeSize - logoSize) / 2,
//               (qrCodeSize - logoSize) / 2,
//               logoSize,
//               logoSize
//             );

//             // Set Data URL for download
//             setDataURL(canvas.toDataURL('image/png'));
//           };
//         }
//       }
//     );
//   }, [websiteurl, eMedhaLogo, qrCodeSize, logoSize]);

//   const handleDownload = () => {
//     if (dataURL) {
//       const link = document.createElement('a');
//       link.href = dataURL;
//       link.download = 'qrcode.png';
//       link.click();
//     }
//   };

//   return (
//     <div>
//       <canvas
//         ref={canvasRef}
//         width={qrCodeSize}
//         height={qrCodeSize}
//         style={{ border: '1px solid #000000', cursor: 'pointer' }}
//         onClick={handleDownload}
//       />
      
//       {/* Display the QR code preview 
//      {dataURL && (
//         <div>
//           <img src={dataURL} alt="QR Code Preview" />
//           <button onClick={handleDownload}>Download QR Code</button>
//         </div>
//       )}
//     */}
//      </div>
//   );
// };

// export default QRCodeWithLogo;



// import React, { useRef, useEffect, useState } from 'react';
// import QRCode from 'qrcode';

// const QRCodeWithLogo = ({ websiteurl, qrCodeSize, eMedhaLogo, logoSize }) => {
//   const canvasRef = useRef(null);
//   const [dataURL, setDataURL] = useState('');

//   useEffect(() => {
//     // Generate QR code using qrcode library
//     QRCode.toCanvas(
//       canvasRef.current,
//       websiteurl,
//       {
//         width: qrCodeSize,
//         margin: 0,
//         color: {
//           dark: '#000000',  // QR code color
//           light: '#ffffff'  // Background color
//         },
//         errorCorrectionLevel: 'H'
//       },
//       (err) => {
//         if (err) {
//           console.error('Error generating QR Code:', err);
//           return;
//         }

//         // Draw logo on top
//         const canvas = canvasRef.current;
//         const ctx = canvas.getContext('2d');

//         if (eMedhaLogo) {
//           const logo = new Image();
//           logo.src = eMedhaLogo;
//           logo.onload = () => {
//             const logoX = (qrCodeSize - logoSize) / 2;
//             const logoY = (qrCodeSize - logoSize) / 2;
//             ctx.drawImage(logo, logoX, logoY, logoSize, logoSize);

//             // Set Data URL for download
//             setDataURL(canvas.toDataURL('image/png'));
//           };
//         } else {
//           // No logo, just set the Data URL
//           setDataURL(canvas.toDataURL('image/png'));
//         }
//       }
//     );
//   }, [websiteurl, eMedhaLogo, qrCodeSize, logoSize]);

//   const handleDownload = () => {
//     if (dataURL) {
//       const link = document.createElement('a');
//       link.href = dataURL;
//       link.download = 'qrcode.png';
//       link.click();
//     }
//   };

//   return (
//     <div>
//       <canvas
//         ref={canvasRef}
//         width={qrCodeSize}
//         height={qrCodeSize}
//         style={{ border: '1px solid #000000', cursor: 'pointer' }}
//         onClick={handleDownload}
//       />
//       {dataURL && (
//         <div>
//           <img src={dataURL} alt="QR Code Preview" />
//           <button onClick={handleDownload}>Download QR Code</button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default QRCodeWithLogo;


import React, { useRef, useEffect, useState } from 'react';
import QRCode from 'qrcode';
import { LiaQrcodeSolid } from "react-icons/lia";
import { Col, Row } from 'react-bootstrap';
const QRCodeWithLogo = ({ websiteurl, qrCodeSize, eMedhaLogo, logoSize }) => {
  const canvasRef = useRef(null);
  const [dataURL, setDataURL] = useState('');

  useEffect(() => {
    // Generate QR code using qrcode library
    QRCode.toCanvas(
      canvasRef.current,
      websiteurl,
      {
        width: qrCodeSize,
        margin: 1, // Adjust margin if necessary
        color: {
          dark: '#000000',  // QR code color
          light: '#ffffff'  // Background color
        },
        errorCorrectionLevel: 'H' // High error correction
      },
      (err) => {
        if (err) {
          console.error('Error generating QR Code:', err);
          return;
        }

        // Draw logo on top
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        if (eMedhaLogo) {
          const logo = new Image();
          logo.src = eMedhaLogo;
          logo.onload = () => {
            const logoX = (qrCodeSize - logoSize) / 2;
            const logoY = (qrCodeSize - logoSize) / 2;
            ctx.drawImage(logo, logoX, logoY, logoSize, logoSize);

            // Set Data URL for download
            setDataURL(canvas.toDataURL('image/png'));
          };
        } else {
          // No logo, just set the Data URL
          setDataURL(canvas.toDataURL('image/png'));
        }
      }
    );
  }, [websiteurl, eMedhaLogo, qrCodeSize, logoSize]);

  const handleDownload = () => {
    if (dataURL) {
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = 'qrcode.png';
      link.click();
    }
  };

  return (
    <>
   <Row >
   <Col xs={12} md={8} className="text-center">
      <canvas
        ref={canvasRef}
        width={qrCodeSize}
        height={qrCodeSize}
        style={{ border: '1px solid #000000' }}
      />
      </Col>
      <Col xs={12} md={4} className="text-center    ">
      <div className='m-5'>
      <button className="btn btn-outline-success" onClick={handleDownload}>Download <LiaQrcodeSolid style={{fontSize:'40px'}}/></button>
    </div>
    </Col>
    </Row>
    </>
  );
};

export default QRCodeWithLogo;
