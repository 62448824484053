import React, { useState } from 'react';
import './Header.css';
import { Navbar, Nav } from 'react-bootstrap';
import logonobg from '../Images/logonobg.png';
import template from '../Images/template.png';
import { FcSearch } from "react-icons/fc";
import logoo1 from '../Images/logoo1.webp'
import icons from '../Images/icons.png';
import NfcStudio from '../Images/NfcStudio.png';


const NavBar = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = () => {
   
    console.log('Searching for:', searchTerm);
    
};
 
  return  ( 
    <section  style={{backgroundColor:'white'}}>
     <Navbar  variant="light" expand="lg" className=' container ' style={{position:''  }}>

    <Navbar.Brand href="/" className="mr-auto">
        <img
          src={logonobg} style={{height:'100px '}}
          height="30"
          className="d-inline-block align-top"
          alt="Logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
    <ul className="nav-items">
      <li className="nav-item">
        <a href="/about" className="nav-link"><span>About Us</span></a>
      </li>
      <li className="nav-item">
        <a href="/pricing" className="nav-link" ><span>Pricing</span></a>
       
      </li>
      <li className="nav-item">
        <a href="/contact" className="nav-link"><span>Contact</span></a>
      </li>
      <li className="nav-item">
        <a href="/login" className="nav-link"><span>Login</span></a>
      </li>
  
    </ul>
 </Navbar.Collapse>
  </Navbar>
<div className="container  text-center">
      <div className="row ">
        <div className="col-md-6 col-sd-12 pt-5 mt-5">
          <h2 style={{ fontFamily: 'sans-serif' }}>
          "Revolutionize Your Experience with Smart NFC Cards - Custom Designs for Every Need"  </h2>
          <p style={{ fontFamily: 'cursive' }}>
          Welcome to the future of connectivity with our cutting-edge NFC card designs. Our NFC cards combine sleek aesthetics with advanced technology to offer you a seamless experience like never before.     </p>
          
          <div className='body'>
          <div className="  search-box">
          <button className=" btn btn-primary btn-search" onClick={handleSearch}  > <FcSearch style={{fontSize:'30px'}}/> </button>
   
            <input
              type="text"
              className="form-control input-search"
              placeholder="Enter search term..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
                 </div>
          </div>
        </div>
    
         
 <div className=" pt-5 mt-5col-md-6 col-sd-12 text-center  logocontainer" style={{ backgroundImage: `url(${NfcStudio})`, height:'350px',width:'350px'}}>
            <img className='rotate' src={logoo1} alt="Logo" />
        </div>


        </div>
      </div>
   

  </section>
  );
};

export default NavBar;


