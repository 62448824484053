// import React, { useEffect } from 'react';
// import { Modal, Button, Row, Col } from 'react-bootstrap';

// const ModalComponent = ({ show, handleClose, image1, image2 }) => {

//   useEffect(() => {
//     return () => {
//       if (image1 && (image1 instanceof File || image1 instanceof Blob)) {
//         URL.revokeObjectURL(renderImage(image1));
//       }
//       if (image2 && (image2 instanceof File || image2 instanceof Blob)) {
//         URL.revokeObjectURL(renderImage(image2));
//       }
//     };
//   }, [image1, image2]);

//   const renderImage = (image) => {
//     if (image instanceof File || image instanceof Blob) {
//       return URL.createObjectURL(image);
//     } else if (typeof image === 'string') {
//       return image;
//     }
//     return null;
//   };

//   return (
//     <Modal show={show} onHide={handleClose}>
//       <Modal.Header closeButton>
//         <Modal.Title>Image Gallery</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Row>
//           <Col className='text-center'>
//             {image1 && (
//               <div>
//                 <img src={renderImage(image1)} alt="Image 1" style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }} />
//               </div>
//             )}
//           </Col>
//           <Col className='text-center'>
//             {image2 && (
//               <div>
//                 <img src={renderImage(image2)} alt="Image 2" style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }} />
//               </div>
//             )}
//           </Col>
//         </Row>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleClose}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default ModalComponent;

// import React, { useEffect, useState } from 'react';
// import { Modal, Button, Row, Col } from 'react-bootstrap';

// const ModalComponent = ({ show, handleClose, image1, image2 }) => {
//   const [image1Url, setImage1Url] = useState(null);
//   const [image2Url, setImage2Url] = useState(null);

//   useEffect(() => {
//     if (image1) {
//       setImage1Url(URL.createObjectURL(image1));
//     }
//     if (image2) {
//       setImage2Url(URL.createObjectURL(image2));
//     }

//     // Clean up object URLs when component unmounts or images change
//     return () => {
//       if (image1Url) {
//         URL.revokeObjectURL(image1Url);
//       }
//       if (image2Url) {
//         URL.revokeObjectURL(image2Url);
//       }
//     };
//   }, [image1, image2]);

//   return (
//     <Modal show={show} onHide={handleClose}>
//       <Modal.Header closeButton>
//         <Modal.Title>Image Gallery</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Row>
//           <Col className='text-center'>
//             {image1Url && (
//               <div>
//                 <img
//                   src={image1Url}
//                   alt="Image 1"
//                   style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }}
//                 />
//               </div>
//             )}
//           </Col>
//           <Col className='text-center'>
//             {image2Url && (
//               <div>
//                 <img
//                   src={image2Url}
//                   alt="Image 2"
//                   style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }}
//                 />
//               </div>
//             )}
//           </Col>
//         </Row>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleClose}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default ModalComponent;


// import React, { useEffect } from 'react';
// import { Modal, Button, Row, Col } from 'react-bootstrap';

// const ModalComponent = ({ show, handleClose, image1, image2 }) => {
//   // Cleanup URLs when the component unmounts
//   useEffect(() => {
//     return () => {
//       if (image1 instanceof File || image1 instanceof Blob) {
//         URL.revokeObjectURL(image1);
//       }
//       if (image2 instanceof File || image2 instanceof Blob) {
//         URL.revokeObjectURL(image2);
//       }
//     };
//   }, [image1, image2]);

//   const renderImage = (file) => {
//     if (file instanceof File || file instanceof Blob) {
//       return URL.createObjectURL(file);
//     } else if (typeof file === 'string') {
//       return file;
//     }
//     return null;
//   };

//   return (
//     <Modal show={show} onHide={handleClose}>
//       <Modal.Header closeButton>
//         <Modal.Title>Image Gallery</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Row>
//           <Col className='text-center'>
//             {image1 && (
//               <div>
//                 <img
//                   src={renderImage(image1)}
//                   alt="Image 1"
//                   style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }}
//                 />
//               </div>
//             )}
//           </Col>
//           <Col className='text-center'>
//             {image2 && (
//               <div>
//                 <img
//                   src={renderImage(image2)}
//                   alt="Image 2"
//                   style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }}
//                 />
//               </div>
//             )}
//           </Col>
//         </Row>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleClose}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default ModalComponent;

// ModalComponent.js

// ModalComponent.js

import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ModalComponent = ({ images = [], show, onClose }) => {
  return (
    <Modal show={show} onHide={onClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Images</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          {images.length === 0 ? (
            <p>No images to display</p>
          ) : (
            images.map((image, index) => (
              image ? (
                <img
                  key={index}
                  src={image}
                  alt={`Image ${index + 1}`}
                  style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                />
              ) : null
            ))
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalComponent;

