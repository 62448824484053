import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import pg11 from '../Images/pg11.png';
import pg22 from '../Images/pg22.png';
import 'aos/dist/aos.css';
import AOS from 'aos';
import './Home.css';
import image from '../Images/image.jpg';
import CardList from '../Theams/CardList';
import nfcstudio from '../Images/nfcstudio.gif';
import Header from '../Header/Header.js';
import Footer from '../Footer/Footer';
// import ThemeSelector from '../Theams/ThemeSelector';
// import styled, { ThemeProvider } from 'styled-components';
// import { themes } from '../Theams/Theams';

// const AppContainer = styled.div`
//   font-family: Arial, sans-serif;
//   color: ${(props) => props.theme.colors.text};
//   background-color: ${(props) => props.theme.colors.background};
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
// `;
const Home = () => {
  // const selectedTheme = themes[0];
  useEffect(() => {
    AOS.init();
  }, []);
  useEffect(() => {
    const spanizeText = () => {
      const mastText = document.querySelectorAll('.js-spanize');
      mastText.forEach((text) => {
        const letters = text.textContent.split('');
        text.textContent = ''; // Clear original text content
        letters.forEach((letter, idx) => {
          const span = document.createElement('span');
          span.textContent = letter;
          span.style.animationDelay = `${0.05 * idx}s`; // Set animation delay
          text.appendChild(span);
        });
      });
    };

    spanizeText(); // Call the function to initialize animation
  }, []);
  return (
    <>
 
  <Header/>
  
    <Container className='text-center  '>
    
      
          
        <sectionn className=" mast col-md-12 col-sd-12 ">
          
          <header className=" mast__header  mt-5 pt-5  ">
            <h2 className="mast__text js-spanize text-center " style={{fontSize:'20px'}}>What is NFC Card?</h2>
            <p className="mast__text js-spanize">
            Whether you need NFC cards for access control, payment solutions, or interactive marketing campaigns, we have the perfect solution for you. Our team of experts will work closely with you to create custom NFC card designs that reflect your brand identity and meet your specific goals.  </p>
            <p className="grow-text mast__text js-spanize" >With NFC technology, you can effortlessly transfer information, make secure payments, and engage customers in a whole new way. Say goodbye to traditional cards and embrace the convenience of NFC technology with our innovative designs.        </p>

          </header>
        </sectionn>
        <Row>
        <Col sm={12} md={6} className="photo-container mt-3 pt-3">
            <div className="photo">
                <img src={image} alt="colorful fishes in the ocean" className="front-side" />
                <div className="photo-desc back-side">
                    <h5 className='mt-4'>NFC Card</h5>
                    <p> "Revolutionize Your Experience with Smart NFC Cards - Custom Designs for Every Need" </p>
                    <a href="/login" className="button">download</a>
                </div>
            </div>
        </Col>

        <Col sm={12} md={6} className=" mt-5 pt-5"  data-aos="fade-right">
            <p className="grow-text mast__text js-spanize">Stand out from the crowd with our SEO-optimized NFC card designs that not only look great but also drive traffic to your business. Enhance your online visibility and attract more customers with our NFC cards that are designed to make a lasting impression.        
          </p>
          <p className="grow-text mast__text js-spanize" >Experience the power of NFC technology with our custom-designed cards that are tailored to your needs. Get in touch with us today to explore the endless possibilities of NFC cards and take your business to new heights.</p>
        </Col>
      </Row>

<div>
<h4>BUILDING ONLINE CONNECTIONS: SMART CARDS AND SOCIAL MEDIA SYNERGY</h4>
<p className="grow-text mast__text js-spanize">Here’s how this synergy is enhancing professional connections:
Seamless Social Media Integration: Smart cards can be encoded with links to your social media profiles, such as LinkedIn, Twitter, or Instagram. This integration allows for a seamless transition from in-person meetings to online connections, as new contacts can instantly follow or connect with you on these platforms.
Customized Sharing Options: Depending on your professional needs, you can customize your smart card to link to different social media platforms.</p>
</div>
<Row >
      <Col className="col-md-6 col-sd-12 mt-5 pt-5" data-aos="flip-right">
        <p className="grow-text mast__text js-spanize"> For example, if you’re in a creative field, linking to your Instagram or Pinterest might be more beneficial, whereas LinkedIn might be more appropriate for corporate networking.
Simplifying the Connection Process: The simplicity of tapping a smart card and instantly connecting on social media eliminates the need to search for names or usernames, reducing the likelihood of lost connections due to misspelt names or forgotten follow-ups.
Dynamic Content Delivery: Beyond just connecting, smart cards can share dynamic content hosted on your social media, such as recent posts, portfolio updates, or professional accomplishments, providing a richer picture of your professional persona.
Tech-Savvy Professional Image: Using smart cards to link to social media also enhances your professional image. It portrays you as a forward-thinking individual who embraces technology for efficient and effective networking.
Adapting to Networking Preferences: As networking preferences continue to evolve, having a smart card that links to social media caters to the growing trend of digital-first interactions, especially among the tech-savvy and younger professionals.</p>
      <a href='/login' >  <button className='btn btn-info'>Get started now</button></a>
        
        </Col>
      
        <Col sm={12} md={6} className="mt-3 pt-4">
            <div className="">
                <img src={nfcstudio} alt="gif" style={{height:'450px'}}  />
                
            </div>
        </Col>
      </Row>
      <br></br>
      <Row  sm={12} md={12} className='text-center mt-2 pt-2'>
        <h2   data-aos='fade-up'>Achieving Design in Two Clicks</h2>
      </Row>
   
<CardList/>
<br></br>


       

       
  
      </Container>
      <Footer />
      </>
   
  );
};

export default Home;
