import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom'; 
import { Card, Col, Row, Modal, Button } from 'react-bootstrap';
import { IoMdCall } from "react-icons/io";
import { TfiEmail } from "react-icons/tfi";
import { FaLocationDot } from "react-icons/fa6";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaGithub } from "react-icons/fa";
import ModalComponents from '../ModalComponent/ModalComponents.js'
import ReactPlayer from 'react-player';
import ModalComponent from '../ModalComponent/ModalComponent.js';
import { Page, Text, View, Document, StyleSheet, Link } from '@react-pdf/renderer';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import Header from '../Header/Header';
import Footer from '../Footer/Footer.js';
const FormContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: ${(props) => props.color};
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
`;

const ButtonStyled = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const FormComponent = () => {
  const { id } = useParams(); 

  const [forms, setForms] = useState([]);
  const navigate = useNavigate();

  const handleBookCard = () => {
    // Navigate to the login page
    navigate('/login');
  };


  const cards = [
    { 
      id: '1', 
      color: '#88F4FF', 
      companyName: 'Company A', 
      designation: 'CEO', 
      phoneNumber: '+1234567890', 
      email: 'ceo@companya.com', 
      address: '123 Main Street, City, Country' 
    },
    { 
      id: '2', 
      color: '#A5CAD2', 
      companyName: 'Company B', 
      designation: 'Manager', 
      phoneNumber: '+9876543210', 
      email: 'manager@companyb.com', 
      address: '456 Park Avenue, Town, Country' 
    },
    { 
      id: '3', 
      color: '#B8E2BA', 
      companyName: 'Company C', 
      designation: 'Manager', 
      phoneNumber: '+9876543210', 
      email: 'manager@companyc.com', 
      address: '789 Broadway, Village, Country' 
    },
    { 
      id: '4', 
      color: '#91ACC8', 
      companyName: 'Company C', 
      designation: 'Manager', 
      phoneNumber: '+9876543210', 
      email: 'manager@companyc.com', 
      address: '789 Broadway, Village, Country' 
    },
  ];


  const [formInputs, setFormInputs] = useState({
    companyName: '',
    image:'',
    name: '',
    designation: '',
    phoneNumber: '',
    email: '',
    address: '',
    location: '',
    whatsappChat: '',
    call: '',
    facebookUrl: '',
    linkedinUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    telegramUrl: '',
    pdf:'',
    ppt:'',
    videoUrl1: '',
    videoUrl2: '',
    website:'',
    websiteUrl:'',
  });




  // Find the selected card based on id
 const selectedCard = cards.find(card => card.id === id);



 const [appliedChanges, setAppliedChanges] = useState({
    image1: null, // Placeholder for image 1
    image2: null, // Placeholder for image 2

 
  });


  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [selectedImage2, setSelectedImage2] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [selectedGalleryImage, setSelectedGalleryImage] = useState(null);

  const [showModall, setShowModall] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState(null);

  // Handle form input changes

  useEffect(() => {
    // Load form data from local storage when component mounts
    const storedFormInputs = JSON.parse(localStorage.getItem('formInputs'));
    if (storedFormInputs) {
      setFormInputs(storedFormInputs);
    }
  }, []);

  useEffect(() => {
    // Save form data to local storage when formInputs change
    localStorage.setItem('formInputs', JSON.stringify(formInputs));
  }, [formInputs]);




  const [imageCount, setImageCount] = useState(''); // Default to 1 image input
  const [images, setImages] = useState([]);

  // Update the image count
  const handleImageCountChange = (e) => {
    const count = Number(e.target.value);
    if (count >= 1 && count <= 10) { // Limit the count between 1 and 10
      setImageCount(count);
      setImages(Array(count).fill(null)); // Reset the images array to match the new count
    }
  };

  const handleImageChangee = (index, e) => {
    const file = e.target.files[0];
    const updatedImages = [...images];
    updatedImages[index] = file;
    setImages(updatedImages);
  };
  // Handle image changes
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleImageChange1 = (e) => {
    const file = e.target.files[0];
    setSelectedImage1(file);
  };

  const handleImageChange2 = (e) => {
    const file = e.target.files[0];
    setSelectedImage2(file);
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  
  const handleShowModall = () => setShowModall(true);
  const handleCloseModall = () => setShowModall(false);


  // Handle gallery button click
  const handleOpenGallery = () => {
    handleShowModal(); // Show the modal
    setSelectedGalleryImage(1); // Set the selected image to Image 1 (you can modify as needed)
  };
  const handleOpenVideos = () => {
    handleShowModall(); // Show the modal
    setSelectedVideos(); // Set the selected image to Image 1 (you can modify as needed)
  };
 

  const handleOpenLocation = () => {
    if (appliedChanges && appliedChanges.location) {
      window.open(appliedChanges.location, '_blank');
    }
  };

  const handleOpenWhatsappChat = () => {
    if (appliedChanges && appliedChanges.whatsappChat) {
      window.open(appliedChanges.whatsappChat, '_blank');
    }
  };




  const downloadVCard = () => {
    const vCardData = `
BEGIN:VCARD
VERSION:3.0
TITLE:${appliedChanges.name}
ORG:${appliedChanges.companyName}
TITLE:${appliedChanges.designation}
TEL:${appliedChanges.call}
EMAIL:${appliedChanges.email}
ADR:${appliedChanges.address}
URL:${appliedChanges.websiteUrl}
END:VCARD
    `.trim();

    const blob = new Blob([vCardData], { type: 'text/vcard' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'contact.vcf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

   

  // Function to handle opening the modal
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleOpenModall = () => {
    setShowModall(true);
  };
  // Function to handle closing the modal


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInputs(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  

  const [cardData,setCardData]=useState([])

console.log(cardData)



  
const handleSubmit = async (e) => {
  e.preventDefault();

  const formData = new FormData();
  if (selectedImage) formData.append('image', selectedImage);
  // Append form data
  Object.keys(formInputs).forEach(key => formData.append(key, formInputs[key]));
  
  // Append images
  images.forEach((image, index) => {
      if (image) formData.append(`image${index + 1}`, image);
  });

  // https://api.nfcdesignstudio.com/API/v1/submit-form

  try {
      const token = localStorage.getItem('token');
      const response = await axios.post('https://api.nfcdesignstudio.com/API/v1/submit-form', formData, {
          headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`,
          },
      });

      if (response.status === 200) {
          const { message, formData } = response.data;
          console.log('Response Data:', formData);
          setFormInputs({ ...formInputs, websiteUrl: formData.websiteUrl });
          alert(message);

          const websiteUrl = formData.websiteUrl;
          if (websiteUrl) {
              localStorage.removeItem('token');
              localStorage.setItem('uniqueUrl', websiteUrl);
              localStorage.setItem('isLoggedIn', 'true');
              navigate(`/${websiteUrl}`);
          }
      } else {
          console.error('Unexpected response status:', response.status);
          alert('Failed to submit form. Please try again.');
      }
  } catch (error) {
      console.error('Error submitting form data', error);
      alert('Failed to submit fform. Please try again.');
  }
};




  return (
    <>
    <Header/>
      <FormContainer style={{backgroundColor:'lightblue'}} >
        <h2>Card Form</h2>
        <form onSubmit={handleSubmit} >
          <FormGroup>
            <Label>Company Logo:</Label>
            <Input 
              type="file" 
              accept="image/*" 
              onChange={handleImageChange} 
            />
          </FormGroup>

          <FormGroup>
            <Label>Company Name:</Label>
            <Input 
              type="text" 
              name="companyName" 
              value={formInputs.companyName} 
              onChange={handleChange} 
            />
          </FormGroup>
          
          <FormGroup>
            <Label>Name:</Label>
            <Input 
              type="text" 
              name="name" 
              value={formInputs.name} 
              onChange={handleChange} 
            />
          </FormGroup>

          <FormGroup>
            <Label>Designation:</Label>
            <Input 
              type="text" 
              name="designation" 
              value={formInputs.designation} 
              onChange={handleChange} 
            />
          </FormGroup>

          <FormGroup>
            <Label>Phone Number:</Label>
            <Input 
              type="tel" 
              name="phoneNumber" 
              value={formInputs.phoneNumber} 
              onChange={handleChange} 
            />
          </FormGroup>

          <FormGroup>
            <Label>Email:</Label>
            <Input 
              type="email" 
              name="email" 
              value={formInputs.email} 
              onChange={handleChange} 
            />
          </FormGroup>

          <FormGroup>
            <Label>Address:</Label>
            <TextArea 
              name="address" 
              value={formInputs.address} 
              onChange={handleChange} 
            />
          </FormGroup>

          <FormGroup>
            <Label>Whatsapp Chat Link:( https://wa.me/91+XXXXXXXXXX )  </Label>
            <Input 
              type="text" 
              name="whatsappChat" 
              value={formInputs.whatsappChat} 
              onChange={handleChange} 
            />
          </FormGroup>

          <FormGroup>
            <Label>Location Link:</Label>
            <Input 
              type="text" 
              name="location" 
              value={formInputs.location} 
              onChange={handleChange} 
            />
          </FormGroup>

          <FormGroup>
            <Label>Call Link:</Label>
            <Input 
              type="text" 
              name="call" 
              value={formInputs.call} 
              onChange={handleChange} 
            />
          </FormGroup>
          <FormGroup>
            <Label>WebSite URL:</Label>
            <Input 
              type="url" 
              name="website" 
              value={formInputs.website} 
              onChange={handleChange} 
            />
          </FormGroup>
          <FormGroup>
  <Label>PDF</Label>
  <Input 
    type="file" 
    accept=".pdf" 
    name="pdf"
    value={formInputs.pdf} 
    onChange={handleChange} 
  />
</FormGroup>

<FormGroup>
  <Label>PPT</Label>
  <Input 
    type="file" 
    accept=".ppt,.pptx" 
    name="ppt"
    value={formInputs.ppt} 
    onChange={handleChange} 
  />
</FormGroup>
         
       <FormGroup>
            <Label>Number of Images:</Label>
            <Input
              type="number"
              value={imageCount}
              onChange={handleImageCountChange}
              placeholder='Enter a Number Of image files (Max-10)'
              min="0"
              max="10"
            />
          </FormGroup>
          {Array.from({ length: imageCount }, (_, index) => (
            <FormGroup key={index}>
              <Label>Image {index + 1}:</Label>
              <Input 
                type="file" 
                accept="image/*" 
                onChange={(e) => handleImageChangee(index, e)} 
              />
            </FormGroup>
          ))}
          <FormGroup>
          <Label for="videoUrl1">Select Video 1:</Label><br />
          <Input
            type="url"
            id="videoUrl1"
            name="videoUrl1"
            value={formInputs.videoUrl1} 
            onChange={handleChange}
          />
        </FormGroup>
        <br />
        <FormGroup>
          <Label for="videoUrl2">Select Video 2:</Label><br />
          <Input
            type="url"
            id="videoUrl2"
            name="videoUrl2"
            value={formInputs.videoUrl2} 
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label >facebookUrl</Label><br />
          <Input
            type="url"
            id="facebookUrl"
            name="facebookUrl"
          
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label >linkedinUrl</Label><br />
          <Input
            type="url"
            id="linkedinUrl"
            name="linkedinUrl"
         
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>instagramUrl</Label><br />
          <Input
            type="url"
            id="instagramUrl"
            name="instagramUrl"
         
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label >twitterUrl</Label><br />
          <Input
            type="url"
            id="twitterUrl"
            name="twitterUrl"
          
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label >telegramUrl</Label><br />
          <Input
            type="url"
            id="telegramUrl"
            name="telegramUrl"
          
            onChange={handleChange}
          />
        </FormGroup>

        <ReCAPTCHA
              sitekey="6LfB4CIqAAAAABTTABOif9nMWUiFuMfIp31_UU5I"
            // onChange={onChangeCaptcha}
            />
          <ButtonStyled type="submit">Apply Changes</ButtonStyled>
        </form>
      </FormContainer>


<br></br>
     <Footer/>
    </>
  );
};


export default FormComponent;
