// ModalComponent.js

import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import ReactPlayer from 'react-player';

const ModalComponents = ({ showw, handleClosee, videoUrl1, videoUrl2 }) => {
  return (
    <Modal show={showw} onHide={handleClosee} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Video Gallery</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6} className='p-4 text-center'>
            {videoUrl1 && (
              <div className="embed-responsive embed-responsive-16by9">
                <ReactPlayer
                  url={  videoUrl1}
                  width="100%"
                  height="100%"
                  controls={true} // Show player controls (play, pause, etc.)
                />
              </div>
            )}
          </Col>
          <Col md={6} className='p-4 text-center'>
            {videoUrl2 && (
              <div className="embed-responsive embed-responsive-16by9">
                <ReactPlayer
                  url={videoUrl2}
                  width="100%"
                  height="100%"
                  controls={true} // Show player controls (play, pause, etc.)
                />
              </div>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClosee}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalComponents;


