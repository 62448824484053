

import React, { useState } from "react";
import { Container, Form, Button, Alert, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Footer from "../../Footer/Footer";
// import './LoginForm.css'; // Import CSS file for styling
import Header from '../../Header/Header';
const LoginForm = ({onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [active, setActive] = useState(false);
  const navigate = useNavigate();
  const [websiteData, setWebsiteData] = useState(null);
  const websiteUrl = 'your-website-url'; 
  const [captchaToken, setCaptchaToken] = useState("");
  //   const handleSubmit = async (e) => {

  //     e.preventDefault();

  //     try {
  //       const response = await fetch('http://localhost:3007/login', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({ email, password }),
  //       });

  //       if (response.ok) {
  //         const data = await response.json();
  //         localStorage.setItem('token', data.token);
  //         setError('');
  //         onLogin(data.token);
  //         navigate("/dashboard");
  //       } else {
  //         const data = await response.json();
  //         setError(data.error || 'Failed to login. Please check your credentials.');
  //       }
  //     } catch (error) {
  //       console.error('Error logging in:', error);
  //       setError('Failed to login. Please try again.');
  //     }
  //   };


  // Inside LoginForm component, after successful login
  
  const onChangeCaptcha = (token) => {
    setCaptchaToken(token); // Store the reCAPTCHA token in state
  };

//   const handleLogin = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await fetch("https://api.nfcdesignstudio.com/API/v1/login", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ email, password }),
//       });
//       // const data = await response.json();
//      localStorage.setItem('loginEmail',email)

//       if (response.status === 200) {
//        navigate("/customer");
//         const data = await response.json();
//         localStorage.setItem("token", data.token); 
//        localStorage.setItem('userData', JSON.stringify({ email, password }));   
//        onLogin(data.token); // Call onLogin function from props to set isLoggedIn state
//         // Redirect to dashboard or another page if needed
//           alert("Login successful!");   
//        //alert(data.message);
       

//       //  const data = await response.json();
       
//       //  onLogin(data.token); // Handle login state and token
//       //  alert("Login successful!");
//       //  localStorage.removeItem('token');
//       //  navigate("/customer");

// // 27:08:2024 time 11:37


//        // Redirect based on whether it's the user's first login
//       //  const firstLogin = localStorage.getItem('firstLogin');
//       //  if (firstLogin === null) {
//       //   localStorage.removeItem('token');
//       //    navigate("/customer"); // Redirect to FormComponent on first login
//       //  } else {
//       //   localStorage.removeItem('token');
//       //   navigate(`/${websiteUrl}`); // Redirect to CardComponent if user has logged in before
//       //  }
    
//        // navigate("/customer"); // Example redirection after successful login





//       } else if (response.status === 500) {
//         // Internal Server Error
//         setError("Server error occurred. Please try again later.");
//         console.error("Internal Server Error");
        
//       } else if (response.status === 503) {
//         // Service Unavailable
//         setError("Service is currently unavailable. Please try again later.");
//         console.error("Service Unavailable");
        
//       } else {
//         // Handle other statuses (e.g., 400 Bad Request, 401 Unauthorized)
//         const data = await response.json();
//         setError(data.error || "Login failed. Please try again.");
//       }
//     } catch (error) {
//       console.error("Error logging in:", error);
//       alert('Login failed. Please try again later.');
//       setError("Login failed. Please try again.");
//     }
//   };









  //     } else {
  //       const data = await response.json();
  //       setError(data.error || "Login failed. Please try again.");
  //     }
  //   } catch (error) {
  //     console.error("Error logging in:", error);
  //     alert('Login failed. Please try again later.');
  //     setError("Login failed. Please try again.");
  //   }
  // };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://api.nfcdesignstudio.com/API/v1/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
  
      const responseData = await response.json(); // Get the JSON response
      console.log("Login Response:", responseData); // Debug the response
  
      if (response.status === 200) {
        if (!responseData.token) {
          setError("Login failed. Token is missing.");
          return;
        }
  
        // Store token and user data
        localStorage.setItem("token", responseData.token);
        localStorage.setItem("loginEmail", email);
        localStorage.setItem("userData", JSON.stringify({ email, password }));
  
        // Handle login state and call onLogin
        // onLogin(responseData.token);
     
        // Check if email exists in the websites table
        const emailCheckResponse = await fetch(`https://api.nfcdesignstudio.com/API/v1/api/check-email?email=${email}`);
        const emailCheckData = await emailCheckResponse.json();
  
        if (emailCheckData.exists) {
          // If email exists, navigate to the associated websiteUrl
          navigate(`/${emailCheckData.websiteUrl}`);
        } else {
          // If email does not exist, navigate to FormComponent
          navigate("/formComponent");
        }
  
        alert("Login successful!");
        
      } else {
        // Handle other statuses (e.g., 400 Bad Request, 401 Unauthorized)
        setError(responseData.error || "Login failed. Please try again.");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      alert("Login failed. Please try again later.");
      setError("Login failed. Please try again.");
    }
  };
  



  return (
    // <Container className="mt-5">
    //   <div className="login-form">
    //     <div className="d-flex flex-row align-items-center justify-content-around">
    //       <img
    //         src="https://emedha.com/assets/img/logo3.png"
    //         alt="Emedha Logo"
    //         className="mb-4 align-self-start"
    //       />
    //       <h2>Login</h2>
    //     </div>
    //     <Form onSubmit={handleLogin}>
    //       <Form.Group controlId="email">
    //         <Form.Label>Email address</Form.Label>
    //         <Form.Control
    //           type="email"
    //           placeholder="Enter your email"
    //           value={email}
    //           onChange={(e) => setEmail(e.target.value)}
    //           required
    //         />
    //       </Form.Group>

    //       <Form.Group controlId="password">
    //         <Form.Label>Password</Form.Label>
    //         <Form.Control
    //           type="password"
    //           placeholder="Enter your password"
    //           value={password}
    //           onChange={(e) => setPassword(e.target.value)}
    //           required
    //         />
    //       </Form.Group>
    //       <div className="text-center mt-3 mb-2 g-recaptcha">
    //         <ReCAPTCHA
    //           sitekey="6LcKzpknAAAAAM3IGeFPi75Qh1HZDkuY9X7wDLfo"
    //           onChange={onChangeCaptcha}
    //         />
    //       </div>

    //       {error && <Alert variant="danger">{error}</Alert>}

    //       <Button variant="primary" type="submit" className="mt-3">
    //         Login
    //       </Button>

    //       <div className="mt-3 d-flex flex-row align-items-center justify-content-center">
    //         <p className="m-2">
    //           <a href="/forgot-password">Forgot Password?</a>
    //         </p>
    //         <p className="m-2 pl-2">
    //           New user? <a href="/">Sign Up</a>
    //         </p>
    //       </div>
    //     </Form>
    //   </div>
    // </Container>
    <>
    <Header/>
    <Container className="mt-5 ">
    <Row className="justify-content-md-center">
      <Col xs={12} md={6} className="login-form">
      <div className="text-center mb-4 d-flex flex-row align-items-center justify-content-start">
            {/* Displaying the logo */}
            <img src="https://emedha.com/assets/img/logo3.png" alt="Emedha Logo" className="logo-img" />
        

          <h2 className="text-center mb-4 ">Login</h2>
          </div>

        <Form >
          <Form.Group controlId="email">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>

          <div className="text-center mt-3 mb-2 g-recaptcha">
            <ReCAPTCHA
              sitekey="6LfB4CIqAAAAABTTABOif9nMWUiFuMfIp31_UU5I"
            // onChange={onChangeCaptcha}
            />
          </div>

          {error && <Alert variant="danger">{error}</Alert>}

          <Button variant="primary" type="submit" className="btn-login mt-3"  onClick={handleLogin}>
            Login
          </Button>

          <div className="mt-3 d-flex flex-row align-items-center justify-content-center">
            <p className="m-2">
              <a href="/forgot-password">Forgot Password?</a>
            </p>
            <p className="m-2 pl-2">
              New user? <a href="/signup">Sign Up</a>
            </p>
          </div>
        </Form>
      </Col>
    </Row>
  </Container>
  <Footer/>
  </>
  );
};

export default LoginForm;
