import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Card from './Card';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { FaUserTie } from "react-icons/fa";

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const CardList = () => {
  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);

  const cards = [
    { 
      id: 1, 
      color: '#88F4FF', 
      companyLogo: 'logo.png', 
      companyName: 'Demo Company ', 
      designation:'Your Designation', 
      phoneNumber: '+1234567890', 
      email: 'ceo@companya.com', 
      address: '123 Main Street, City, Country' 
    },
    { 
      id: 2, 
      color: '#A5CAD2', 
      companyLogo: 'logo.png', 
      companyName: 'Demo Company ', 
      designation:'Your Designation', 
      phoneNumber: '+9876543210', 
      email: 'ceo@companya.com', 
      address: '123 Main Street, City, Country' 
    },
    { 
      id: '3', 
      color: '#B8E2BA', 
      companyLogo: 'logo.png', 
      companyName: 'Demo Company ', 
      designation:'Your Designation', 
      phoneNumber: '+9876543210', 
      email: 'ceo@companya.com', 
      address: '123 Main Street, City, Country' 
    },
    { 
      id: '4', 
      color: '#91ACC8', 
      companyLogo: 'logo.png', 
      companyName: 'Demo Company ', 
      designation:'Your Designation', 
      phoneNumber: '+9876543210', 
      email: 'ceo@companya.com', 
      address: '123 Main Street, City, Country' 
    },
    // Add more cards as needed
  ];

  const handleCardClick = (cardId, color) => {
    setSelectedCard(cardId);
    setSelectedColor(color);
    navigate(`/form/${cardId}`);
   
  };

  return (
    
    <CardsContainer  >
      <Row >
      {cards.map((card) => (
        <Card 
          key={card.id}
          id={card.id}
          color={card.color}
          companyLogo={card.companyLogo}
          companyName={card.companyName}
          designation={card.designation}
          phoneNumber={card.phoneNumber}
          email={card.email}
          address={card.address}
          onClick={() => handleCardClick(card.id, card.color)}
        />
      ))}
      </Row>
    </CardsContainer>
  );
};

export default CardList;

// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import Card from './Card';
// import styled from 'styled-components';
// import FormPage from './FormPage'; // Import your Form component




// const CardsContainer = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   align-items: center;
//   margin-top: 20px;
// `;

// const CardList = () => {
//   const [selectedCard, setSelectedCard] = useState(null); // State to track selected card

//   const cards = [
//     { 
//       id: 1, 
//       color: '#FF6347', 
//       companyLogo: 'path/to/logo.png', 
//       companyName: 'Company A', 
//       designation: 'CEO', 
//       phoneNumber: '+1234567890', 
//       email: 'ceo@companya.com', 
//       address: '123 Main Street, City, Country' 
//     },
//     { 
//       id: 2, 
//       color: '#4CAF50', 
//       companyLogo: 'path/to/logo.png', 
//       companyName: 'Company B', 
//       designation: 'Manager', 
//       phoneNumber: '+9876543210', 
//       email: 'manager@companyb.com', 
//       address: '456 Park Avenue, Town, Country' 
//     },
//     // Add more cards as needed
//   ];

//   const handleCardClick = (cardId) => {
//     setSelectedCard(cardId);
//   };

//   return (
//     <div>
//       <CardsContainer>
//         {cards.map((card) => (
//           <Card
//             key={card.id}
//             id={card.id}
//             color={card.color}
//             companyLogo={card.companyLogo}
//             companyName={card.companyName}
//             designation={card.designation}
//             onClick={() => handleCardClick(card.id)}
//           />
//         ))}
//       </CardsContainer>
//       {selectedCard && (
//         <FormPage
//           key={selectedCard}
//           companyName={cards[selectedCard - 1].companyName}
//           designation={cards[selectedCard - 1].designation}
//           phoneNumber={cards[selectedCard - 1].phoneNumber}
//           email={cards[selectedCard - 1].email}
//           address={cards[selectedCard - 1].address}
//         />
//       )}
//     </div>
//   );
// };

// export default CardList;
