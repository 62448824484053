// import React from 'react';
// import { Container } from 'react-bootstrap';
// import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaGithub } from "react-icons/fa";
// import { FcGoogle } from "react-icons/fc";
// import './Footer.css'; // Assuming you have additional CSS for custom styles

// const Footer = () => {
//   return (
//     <footer className="bg-light text-center text-dark">
//       <Container fluid className="p-3">
//         <div className='d-flex justify-content-center align-items-center flex-wrap'>
//           <a className='icon'><FaFacebookF style={{ fontSize: '20px' }} /></a>
//           <a className='icon'><FaTwitter style={{ fontSize: '20px' }} /></a>
//           <a className='icon'><FaLinkedinIn style={{ fontSize: '20px' }} /></a>
//           <a className='icon'><FaInstagram style={{ fontSize: '20px' }} /></a>
//           <a className='icon'><FcGoogle style={{ fontSize: '20px' }} /></a>
//           <a className='icon'><FaGithub style={{ fontSize: '20px' }} /></a>
//         </div>
//         <hr className="my-4" />
//         <div className="text-center p-3" style={{ color: 'black', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
//           © {new Date().getFullYear()} eMedha Technologies
//         </div>
//       </Container>
//     </footer>
//   )
// }

// export default Footer;


import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaGithub } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import './Footer.css';
import logonobg from '../Images/logonobg.png';

const Footer = () => {
  return (
    <footer className="bg-light  text-center text-dark  relative-bottom ">
     <Container fluid className="bg-light ">
      <Row className="py-4">
            <Col xs={12} sm={6} md={6} className="mb-4">
          <div>
          <img src={logonobg} alt='logo' style={{height:'130px'}}/>
          </div>
        </Col>
        <Col xs={6} sm={6} md={3} className="mb-4">
          <div>
            <h2 style={{color:'darkblue'}}><strong>Info</strong></h2>
            <ul className="list-unstyled" >
              <li><a href="/" style={{textDecoration:'none',color:'blue'}}>Home</a></li>
              <li><a href="/about" style={{textDecoration:'none',color:'blue'}}>About Us</a></li>
              <li><a href="/pricing" style={{textDecoration:'none',color:'blue'}}>Pricing</a></li>
              <li><a href="/contact" style={{textDecoration:'none',color:'blue'}}>Contact</a></li>
              <li><a href='/login' style={{textDecoration:'none',color:'blue'}}>login</a></li>
            </ul>
          </div>
        </Col>
        <Col xs={6} sm={6} md={3} className="mb-4">
          <div>
            <h2 style={{color:'darkblue'}}><strong>Support</strong></h2>
            <ul className="list-unstyled">
              <li><a href="#"  style={{textDecoration:'none',color:'blue'}}>Terms &amp; conditions</a></li>
              <li><a href="#"  style={{textDecoration:'none',color:'blue'}}>Privacy Policy</a></li>
              <li><a href="#"  style={{textDecoration:'none',color:'blue'}}>Cancellation &amp; Refund Policy</a></li>
              <li><a href="#"  style={{textDecoration:'none',color:'blue'}}>Contact</a></li>
            </ul>
          </div>
        </Col>
    
      </Row>
    </Container>

  <Container fluid className="p-3 m-3">

        <div className=' d-flex justify-content-center align-items-center flex-wrap  p-3    '>
            <ol>
                <li>
                <a href='http://facebook.com/emedha' target='blank'   > <FaFacebookF className='icon' style={{ fontSize: '20px'}} /></a>
                    </li>
                    <li>
                    <a href='http://twitter.com/emedha'  target='blank' >     <FaTwitter  className='icon' style={{ fontSize: '20px' }} /></a>
                        </li>
                        <li>
                        <a href='https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGclQoFptVOmgAAAX8BQGnouHoPV4wuF83eoIvcN93ajDdA4_fik37UYiAzFDcfTzHyCS6BfJi8YQqho_XdmLEJAzJQY1KKMW2fYY76_S0-tDow_U0ANFFlXz_CATlzYjikjYU=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Femedha-technologies'  target='blank'  >    < FaLinkedinIn  className='icon' style={{ fontSize: '20px' }} /></a>
                            </li>
                            <li>
                            <a href='https://www.instagram.com/emedhaindia?igsh=NTc4MTIwNjQ2YQ=='  target='blank'  >    <FaInstagram   className='icon'style={{  fontSize: '20px' }} /></a>
                                </li>
                                <li>
                                <a href='https://emedha.com/contact#'  target='blank'  >  <  FcGoogle className='icon'style={{ fontSize: '20px' }} /></a>
                                    </li>
                                  
                </ol>
                </div>
                {/* <hr className="my-4" /> */}
        <div className="text-center p-3 " style={{ color:'black',backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
          © {new Date().getFullYear()} Copyright:  eMedha Technologies
        
        </div>
        </Container>
    </footer>
       
  )
}

export default Footer;
