// // ModalComponent.js

import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';

const ModalComponent = ({ show, handleClose, image1, image2 }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Image Gallery</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className=''>
            {image1 && (
              <div>
                <img src={URL.createObjectURL(image1)} alt="Image 1" style={{ width: '200px', height: '200px', borderRadius: '50%' }} />
              </div>
            )}
          </Col>
          <Col className=''>
            {image2 && (
              <div>
                <img src={URL.createObjectURL(image2)} alt="Image 2" style={{ width: '200px', height: '200px', borderRadius: '50%' }} />
              </div>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalComponent;


// import React, { useEffect } from 'react';
// import { Modal, Button, Row, Col } from 'react-bootstrap';

// const ModalComponent = ({ show, handleClose, image, image1, image2 }) => {
//   // Cleanup URLs when the component unmounts
//   useEffect(() => {
//     return () => {
//       if (image instanceof File || image instanceof Blob) {
//         URL.revokeObjectURL(image);
//       }
//       if (image1 instanceof File || image1 instanceof Blob) {
//         URL.revokeObjectURL(image1);
//       }
//       if (image2 instanceof File || image2 instanceof Blob) {
//         URL.revokeObjectURL(image2);
//       }
//     };
//   }, [image, image1, image2]);
//   const renderImage = (file) => {
//     if (file instanceof File || file instanceof Blob) {
//       return URL.createObjectURL(file);
//     } else if (typeof file === 'string') {
//       return file;
//     }
//     return null;
//   };

//   return (
//     <Modal show={show} onHide={handleClose}>
//       <Modal.Header closeButton>
//         <Modal.Title>Image Gallery</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Row>
//           <Col className='text-center'>
//             {image && (
//               <div>
//                 <img src={renderImage(image)} alt="Image" style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }} />
//               </div>
//             )}
//           </Col>
//           <Col className='text-center'>
//             {image1 && (
//               <div>
//                 <img src={renderImage(image1)} alt="Image 1" style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }} />
//               </div>
//             )}
//           </Col>
//           <Col className='text-center'>
//             {image2 && (
//               <div>
//                 <img src={renderImage(image2)} alt="Image 2" style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }} />
//               </div>
//             )}
//           </Col>
//         </Row>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleClose}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default ModalComponent;
