import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FaUserTie } from "react-icons/fa";
import 'aos/dist/aos.css';
import AOS from 'aos';
import { Col, Row } from 'react-bootstrap';
import CompanyLogoo from './companyLogoo.png';
import { IoMdCall } from "react-icons/io";
import { TfiEmail } from "react-icons/tfi";
import { FaLocationDot } from "react-icons/fa6";
import { Navigate } from 'react-router-dom';

const CardContainer = styled.div`
  width: 260px;
  height: auto;
  border-radius: 8px;
  margin: 20px;
  box-shadow: 0 4px 8px rgba(10, 10  ,10, 0.1);
  padding: 20px;
  background-color: ${(props) => props.color};
  cursor: pointer; /* Ensure cursor changes to pointer on hover */
`;

const CompanyLogo = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
 
`;

const CompanyName = styled.div`
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
 margin-bottom: 20px;
`;

const Designation = styled.div`
 font-size: 13px;
  font-style: italic;
  margin-bottom: 10px;
`;

const ContactButton = styled.button`
  margin-right: 10px;
  margin-bottom: 20px;
  padding: 8px 12px;
  font-size: 11px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;


const CardDetails = styled.div`
  margin-top: 20px;
  margin-right:45px

  
`;

const Card = ({ id, color, companyLogo, companyName, designation, phoneNumber , email  , address , onClick }) => {
  const handleCardClick = () => {
    if (onClick) {
 
      onClick(id, color);
    }
  };

  const handleCall = () => {
    console.log('Calling...');
  };

  const handleWhatsapp = () => {
    console.log('Opening WhatsApp...');
  };

  const handleDirection = () => {
    console.log('Opening direction...');
  };

  const handleMail = () => {
    console.log('Opening email...');
  };
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <CardContainer color={color} onClick={handleCardClick} className=" col-md-6 col-sd-12 mt-5 pt-5" data-aos="flip-right">
      <Row>
        <Col>
      <CompanyLogo src={CompanyLogoo}  />
      </Col>
      <Col >
      <CompanyName>{companyName}</CompanyName>
      <Designation>{designation}</Designation>
      </Col>  
      </Row>
      <div>
        <ContactButton onClick={handleCall}>Call</ContactButton>
        <ContactButton onClick={handleWhatsapp}>WhatsApp</ContactButton>
        <ContactButton onClick={handleDirection}>Location</ContactButton>
     
        <ContactButton onClick={handleMail}>Gallery</ContactButton>
        <ContactButton onClick={handleMail}>Videos</ContactButton>
        <ContactButton onClick={handleMail}>Feedback</ContactButton>
      </div>
    
      <CardDetails>
        <div><IoMdCall />{phoneNumber}</div>
        <div> <TfiEmail /> {email}</div>
        <div><FaLocationDot />  {address}</div>
        <div>{}</div>
      </CardDetails>
    </CardContainer>
  );
};

export default Card;



