import React from 'react'
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
const Contact = () => {
  return (
    <>
 
    <Header/>
    <div>Contact</div>
    <Footer />
    </>
  )
}

export default Contact