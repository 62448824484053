
import './Signup.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
const SignUpForm = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    mobile: '',
  });

  const [formErrors, setFormErrors] = useState({
    username: false,
    email: false,
    password: false,
    confirmPassword: false,
    mobile: false,
  });

  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate=useNavigate()

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // Clear the error state when user starts typing in the field
    if (formErrors[e.target.name]) {
      setFormErrors({ ...formErrors, [e.target.name]: false });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate all fields are filled
    let hasErrors = false;
    const newErrors = { ...formErrors };

    Object.keys(formData).forEach((key) => {
      if (formData[key].trim() === '') {
        newErrors[key] = true;
        hasErrors = true;
      } else {
        newErrors[key] = false;
      }
    });

    setFormErrors(newErrors);

    if (hasErrors) {
      setError('Please fill out all fields.');
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      alert('Passwords do not match');
      return;
    }   //https://api.nfcdesignstudio.com/API/v1/signup

    try {
      const response = await axios.post('https://api.nfcdesignstudio.com/API/v1/signup', formData);

      if (response.status === 200) {
        alert('User registered successfully');
        navigate('/otp-form')
        setFormData({
          username: '',
          email: '',
          password: '',
          confirmPassword: '',
          mobile: '',
        });
        setFormErrors({
          username: false,
          email: false,
          password: false,
          confirmPassword: false,
          mobile: false,
        });
      } else {
        setError('Signup failed. Please try again.');
      }
    } catch (error) {
      if (error.response && error.response.data.error) {
        alert(error.response.data.error);
      } else {
        setError('Signup failed. Please try again.');
      }
    }
  };



  return (
    <>
    <Header/>
    <Container fluid >
      <Row className="justify-content-center  align-items-center full-height">
        <Col md={6} className='m-5'>
          <div className="signup-form p-5 rounded">
            <h2 className="text-center mb-4"><img src="https://emedha.com/assets/img/logo3.png" alt='emedha-logo' /> Sign Up</h2>

            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formUsername" className={`mt-4 ${formErrors.username ? 'has-error' : ''}`}>
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formEmail" className={`mt-4 ${formErrors.email ? 'has-error' : ''}`}>
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formMobile" className={`mt-4 ${formErrors.mobile ? 'has-error' : ''}`}>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter mobile number"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formPassword" className={`mt-4 ${formErrors.password ? 'has-error' : ''}`}>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formConfirmPassword" className={`mt-4 ${formErrors.confirmPassword ? 'has-error' : ''}`}>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <div className="text-center mt-3 mb-2 g-recaptcha">
            <ReCAPTCHA
              sitekey="6LfB4CIqAAAAABTTABOif9nMWUiFuMfIp31_UU5I"
            // onChange={onChangeCaptcha}
            />
          </div>

              {error && <Alert variant="danger">{error}</Alert>}

              <Button variant="primary" type="submit" className="w-100 mt-4 btn-signup">
                Sign Up
              </Button>
              <div className="mt-3 d-flex flex-row align-items-center justify-content-center">
            
            <p className="m-2 pl-2">
              Already a member? <a href="/login">Login</a>
            </p>
          </div>
            </Form>
          </div>
        </Col>
      </Row>
      <br></br>
    </Container>
    <Footer/>
    </>
  );
};

export default SignUpForm;

