
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Button, Card, Col, Row } from 'react-bootstrap';
import './CardComponent.css';
import {  FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaGithub,FaTelegramPlane } from 'react-icons/fa';
import { IoMdCall } from "react-icons/io";
import { TfiEmail } from "react-icons/tfi";
import { FaLocationDot } from "react-icons/fa6";
import ModalComponent from './ModalComponent';
//import ModalComponent from  '../ModalComponent/ModalComponent';
import ModalComponents from '../ModalComponent/ModalComponents';
import CardList from './CardList';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import eMedhaLogo from './eMedhaLogo.png'
// import ModalComponent from '../ModalComponent/ModalComponent';
import QRCode from 'qrcode.react';
import QRCodeWithLogo from '../QRCode/QRCodeWithLogo';
import FormComponent from './FormComponent';
import EditCardModal from './EditCardModal';
import { FaEdit } from "react-icons/fa";
const CardContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
`;

const FormContainer = styled.div`
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
`;

const CardComponent = () => {
  // const [cards, setCards] = useState([]);
 
  const [showModal, setShowModal] = useState (false);
//  const [selectedImage, setSelectedImage] = useState(null);
   
  const [showModall, setShowModall] = useState(false);
  const { websiteUrl } = useParams();
  const [websiteData, setWebsiteData] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [isEditButtonVisible, setIsEditButtonVisible] = useState(true);

  const [images1, setImages1] = useState([]);
  
  console.log(images1)

  const [cards, setCards] = useState([])
  const [selectedImage, setSelectedImage] = useState({
    image1: null, // Placeholder for image 1
    image2: null, // Placeholder for image 2

 
  });
 // const [websiteurl, setWebsiteurl] = useState(''); // Initialize with default or dynamic value
  
  useEffect(() => {
    const fetchWebsiteData = async () => {
      try {
         const response = await axios.get(`https://api.nfcdesignstudio.com/API/v1/${websiteUrl}`);
       //   const response = await axios.get(`https://api.nfcdesignstudio.com/API/v1/${websiteUrl}`);
  
        if (Array.isArray(response.data) && response.data.length > 0) {
          setCards(response.data); // Extract the first object from the array
       
          console.log(response.data.images)
         
        } else {
          setCards(response.data);
        }
        console.log('Fetched website data:', response.data);
        setImages1(response.data.images || []);
      } catch (error) {
        console.error('Error fetching website data:', error);
      }
    };

    fetchWebsiteData();
  }, [websiteUrl]);

  console.log(cards)


  const websiteurl = (`https://nfcdesignstudio.com/${websiteUrl}`);

  const qrCodeSize = 256; // Size of the QR code
  const logoSize = 80;    // Size of the logo/image
  const logoMargin = 20;  // Margin around the logo


 
  // useEffect(() => {
  //   const fetchContactData = async () => {
  //     try {
  //       const response = await axios.get('https://api.nfcdesignstudio.com/API/v1/get-form-data');
  //       setContacts(response.data); // Assuming response.data is an array of contacts
  //       console.log('Fetched contact data:', response.data);
  //     } catch (error) {
  //       if (error.response && error.response.status === 404) {
  //         console.error('Error: Endpoint not found (404)');
  //       } else {
  //         console.error('Error fetching contact data:', error);
  //       }
  //     }
  //   };

  //   fetchContactData();
  // }, []);
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  // useEffect(() => {
  //   // Load form data from local storage
  //   const storedCards = JSON.parse(localStorage.getItem('forms')) || [];
  //   setCards(storedCards);
  // }, []);


  const [selectedGalleryImage, setSelectedGalleryImage] = useState(null);

  
  const [selectedVideos, setSelectedVideos] = useState(null);

  const handleCloseModal = () => setShowModal(false);

  
  // const handleShowModall = () => setShowModall(true);
  const handleCloseModall = () => setShowModall(false);


  
  const handleShowModal = (image1, image2) => {
    setSelectedImage({ image1, image2 });
    setShowModal(true);
  };

  const handleOpenLocation = () => {
    if (cards && cards.location) {
      window.open(cards.location, '_blank');
    }
  };

  const handleOpenWhatsappChat = () => {
    if (cards && cards.whatsappChat) {
      window.open(cards.whatsappChat, '_blank');
    }
    
  }; 

//27:08:2024 time 11:58
const [showModal1, setShowModal1] = useState(false);
const [cardData, setCardData] = useState({
  companyName: '',
  name: '',
  designation: '',
  phoneNumber: '',
  email: '',
  address: '',
  whatsappChat: '',
  location: '',
  call: '',
  website: '',
  videoUrl1: '',
  videoUrl2: '',
  facebookUrl: '',
  linkedinUrl: '',
  instagramUrl: '',
  twitterUrl: '',
  telegramUrl: '',
  images: [], // This can be an array of image URLs or file objects
});

// const handleSave = (updatedData) => {
//   // Handle saving the updated data
//   console.log('Updated Data:', updatedData);
//   setIsEditButtonVisible(false);
//   setShowModal(false);
// };
 


const handleSave = async (updatedData) => {
  try {
    // Assuming an API endpoint exists to save the updated data
    await axios.post('http://api.nfcdesignstudio.com/API/v1/api/updateCardData', updatedData);
    setIsEditButtonVisible(false); // Hide the "Edit Card" button after saving changes
    setShowModal1(false);
  } catch (error) {
    console.error('Error saving card data:', error);
  }
};


  const handleOpenModall = () => {
    setShowModall(true);
  };
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const [images, setImages] = useState([]);


  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    setImages(files);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  // const handleImageChange = (newImages) => {
  //   setImages(newImages);
  // };
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);

const downloadVCard = () => {
  // Ensure cards is populated
  if (!Array.isArray(cards) || cards.length === 0) {
    console.error('Invalid or empty cards list.');
    return;
  }

  // Generate vCard data for each card and combine them
  const vCardData = cards.map(cards => `
BEGIN:VCARD
VERSION:3.0
TITLE:${cards.name || ''}
ORG:${cards.companyName || ''}
TITLE:${cards.designation || ''}
TEL:${cards.phoneNumber || ''}
EMAIL:${cards.email || ''}
ADR:${cards.address || ''}
URL:${cards.websiteUrl || ''}
END:VCARD
`.trim()).join('\n\n'); // Double newlines to separate vCards

  // Create a Blob from the vCard data
  const blob = new Blob([vCardData], { type: 'text/vcard' });
  const url = URL.createObjectURL(blob);

  // Create a link element to trigger the download
  const a = document.createElement('a');
  a.href = url;
  a.download = 'contacts.vcf'; // File name
  document.body.appendChild(a);
  a.click();

  // Clean up
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
const handleOpenImageUploadModal = () => {
  setShowImageUploadModal(true);
};

const handleCloseImageUploadModal = () => {
  setShowImageUploadModal(false);
};


const handleEditCard = async (email) => {
  try {
    const response = await fetch(`https://api.nfcdesignstudio.com/API/v1/api/getCardData?email=${email}`);
    const data = await response.json();
    setCardData(data[0]);
    setShowModal1(true);
  } catch (error) {
    console.error('Error fetching card data:', error);
  }
};

const loginEmail=localStorage.getItem("loginEmail")


const handleButtonClick = () => {
  setShowModal1(true);
  handleEditCard(loginEmail);
};


const handleFileOpen = (url) => {
  // Forcing download
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank'; // Open in a new tab
  link.rel = 'noopener noreferrer'; // Security improvement
  link.download = ''; // Suggests downloading the file
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

  return (
    <>
   {/* <CardList/> */}
    <CardContainer >
  

      {/* <h2>Cards</h2> */}
     
          <FormContainer  style={{backgroundColor:'lightblue'}}>
            <div>
            <Row>
              <Col md={6}>
                {cards.image && (
                  <img variant="top" src={cards.image} alt="Card image" style={{  width: '250px', height: '250px', borderRadius: '100%' }} />
                )}
                 </Col>
                 <Col md={6} className='text-left p-5'>
                {cards.companyName && <h3>{cards.companyName}</h3>}
                {cards.name && <h4>{cards.name}</h4>}
                {cards.designation && <p>{cards.designation}</p>}
                {cards.phoneNumber && (
                  <p><IoMdCall /> {cards.phoneNumber}</p>
                )}
              
                </Col>
                </Row>
                <Row>
              <Col className='p-4'>
                {cards.whatsappChat && (
                  <div>
                    <a className='text-center'  href={cards.whatsappChat} style={{ textDecoration: 'none' }}>
                   <button type="button"  target="_blank" className="btn btn-outline-primary"> Whatsapp Chat</button>
                   </a>
                  </div>
                )}
              </Col>
              <Col className='p-4'>
                {cards.location && (
                  <div><a className='text-center' href={cards.location}  target='blank'style={{ textDecoration: 'none' }}>
                    <button className="btn btn-outline-primary"   target="_blank" >Open Location </button>
                    </a>
                  </div>
                )}
              </Col>
              <Col className='p-4'>
                {cards.call && (
                  <div>
                    <a className='text-center' href={`tel:${cards.call}`}   target="_blank"style={{ textDecoration: 'none' }}>
                      <button className='text-center btn btn-outline-primary'> Call Now</button>
                    </a>
                  </div>
                )}
              </Col>
              <Col className='p-4'>
                {cards.website && (
                  <div>
                    <a className='text-center' href={cards.website}  target="_blank"style={{ textDecoration: 'none' }}>
                      <button className='text-center btn btn-outline-primary'> WebSite URL</button>
                    </a>
                  </div>
                )}
              </Col>
            </Row>
            {/* <button className="btn btn-outline-primary m-3" onClick={toggleModal}>
        Gallery
      </button> */}


     
      <button className='btn btn-outline-primary m-3' onClick={handleShowModal}>Gallery</button>
     
      <ModalComponent images={images1} show={showModal} onClose={handleCloseModal} />
  
    <ModalComponent images={images} />
     <button className="btn btn-outline-primary m-3"  onClick={handleOpenModall}>
        Videos
      </button>

    <button
        className='btn btn-outline-primary m-3'
        onClick={() => handleFileOpen(cards.pdf)}
      >
        PDF
      </button>

      <button
        className='btn btn-outline-primary m-3'
        onClick={() => handleFileOpen(cards.ppt)}
      >
        PPT
      </button>
    
    <ModalComponents
        showw={showModall}
        handleClosee={handleCloseModall}
        videoUrl1={cards.videoUrl1}
        videoUrl2={cards.videoUrl2}
      />
      
 {cards.location && (     
                  <p><IoMdCall/> +91 {cards.phoneNumber}</p>
                )}
                {cards.email && (
                  <p><TfiEmail /> {cards.email}</p>
                )}
                {cards.address && <p><FaLocationDot />{cards.address}</p>}
               
                <Row>
                <Col className='m-4'>
                <ol>
                <li>{cards.facebookUrl && (
                  <p><a href={cards.facebookUrl} target="_blank" ><FaFacebookF className='icon'/></a></p>
                )}</li>
               <li>{cards.linkedinUrl && (
                  <p><a href={cards.linkedinUrl} target="_blank" ><FaLinkedinIn className='icon'/></a></p>
                )}</li>
               <li> {cards.instagramUrl && (
                  <p><a href={cards.instagramUrl} target="_blank" ><FaInstagram className='icon' /></a></p>
                )}</li>
               <li> {cards.twitterUrl && (
                  <p><a href={cards.twitterUrl} target="_blank" ><FaTwitter className='icon'/></a></p>
                )}</li>
               <li> {cards.telegramUrl && (
                  <p><a href={cards.telegramUrl} target="_blank" ><FaTelegramPlane className='icon'/></a></p>
                )}</li>
                </ol>
           </Col>
           <Col className='p-5 m-4'>
                <button type="button" className="btn btn-outline-success" onClick={downloadVCard}>
                  Download vCard
                </button>
                </Col>
           </Row>
          
           <Row>
            <Col className='text-start'>
            
     
   
            <QRCodeWithLogo
                  websiteurl={websiteurl}
                  qrCodeSize={qrCodeSize}
                  // eMedhaLogo={eMedhaLogo}
                  logoSize={logoSize}
                />
   
   
     
   
            </Col>
           </Row>
            </div>
          </FormContainer>
    

    </CardContainer>
    <div>
    {isEditButtonVisible && loginEmail === cards.email && ( // Ensure button is shown only for the owner
        <Button variant="outline-primary" onClick={handleButtonClick}>Edit Card</Button>
      )}

      <EditCardModal
        show={showModal1}
        onClose={() => setShowModal1(false)}
        cardData={cardData}
        onSave={handleSave}
      />
    </div>
    </>
  );
};

export default CardComponent;
