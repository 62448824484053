import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom'; 
import { Card, Col, Row, Modal, Button } from 'react-bootstrap';
import { IoMdCall } from "react-icons/io";
import { TfiEmail } from "react-icons/tfi";
import { FaLocationDot } from "react-icons/fa6";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn,FaTelegramPlane, FaGithub } from "react-icons/fa";
import ModalComponents from '../ModalComponent/ModalComponents.js'
import ReactPlayer from 'react-player';
import ModalComponent from '../ModalComponent/ModalComponent.js';
import { Page, Text, View, Document, StyleSheet, Link } from '@react-pdf/renderer';
import axios from 'axios';
import Footer from '../Footer/Footer.js';
import Header from '../Header/Header';
const FormContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: ${(props) => props.color};
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
`;

const ButtonStyled = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const FormPage = () => {
  const { id } = useParams(); 

  const [forms, setForms] = useState([]);
  const navigate = useNavigate();

  const handleBookCard = () => {
    // Navigate to the login page
    navigate('/login');
  };


  const cards = [
    { 
      id: '1', 
      color: '#88F4FF', 
      companyName: 'Company A', 
      designation: 'CEO', 
      phoneNumber: '+1234567890', 
      email: 'ceo@companya.com', 
      address: '123 Main Street, City, Country' 
    },
    { 
      id: '2', 
      color: '#A5CAD2', 
      companyName: 'Company B', 
      designation: 'Manager', 
      phoneNumber: '+9876543210', 
      email: 'manager@companyb.com', 
      address: '456 Park Avenue, Town, Country' 
    },
    { 
      id: '3', 
      color: '#B8E2BA', 
      companyName: 'Company C', 
      designation: 'Manager', 
      phoneNumber: '+9876543210', 
      email: 'manager@companyc.com', 
      address: '789 Broadway, Village, Country' 
    },
    { 
      id: '4', 
      color: '#91ACC8', 
      companyName: 'Company C', 
      designation: 'Manager', 
      phoneNumber: '+9876543210', 
      email: 'manager@companyc.com', 
      address: '789 Broadway, Village, Country' 
    },
  ];


  const [formInputs, setFormInputs] = useState({
    companyName: '',
    image:'',
    name: '',
    designation: '',
    phoneNumber: '',
    email: '',
    address: '',
    location: '',
    whatsappChat: '',
    call: '',
    facebookUrl: '',
    linkedinUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    telegramUrl: '',
    image1: null, // Placeholder for image 1
    image2: null, // Placeholder for image 2
    videoUrl1: '',
    videoUrl2: '',
  });




  // Find the selected card based on id
 const selectedCard = cards.find(card => card.id === id);

//  const [formInputs, setFormInputs] = useState({
//     companyName: selectedCard.companyName,
//     name:selectedCard.selectedCard,
//     designation: selectedCard.designation,
//     phoneNumber: selectedCard.phoneNumber,
//     email: selectedCard.email,
//     address: selectedCard.address,
//     location:selectedCard.location,
//     whatsappChat:selectedCard.whatsappChat,
//     call:selectedCard.call,
//     facebookUrl: selectedCard.facebookUrl,
// linkedinUrl:selectedCard.linkedinUrl,
// instagramUrl:selectedCard.instagramUrl,
// twitterUrl:selectedCard.twitterUrl,
// telegramUrl:selectedCard.telegramUrl ,
// videoUrl2:selectedCard.videoUrl2,
// videoUrl1:selectedCard.videoUrl1,
// image:null,
// image1: null, // Placeholder for image 1
// image2: null, // Placeholder for image 2

//   });

 const [appliedChanges, setAppliedChanges] = useState({
    image1: null, // Placeholder for image 1
    image2: null, // Placeholder for image 2

 
  });


  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [selectedImage2, setSelectedImage2] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [selectedGalleryImage, setSelectedGalleryImage] = useState(null);

  const [showModall, setShowModall] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState(null);

  // Handle form input changes

  useEffect(() => {
    // Load form data from local storage when component mounts
    const storedFormInputs = JSON.parse(localStorage.getItem('formInputs')) || [];
    if (storedFormInputs) {
      setFormInputs(storedFormInputs);
    }
  }, []);

  useEffect(() => {
    // Save form data to local storage when formInputs change
    localStorage.setItem('formInputs', JSON.stringify(formInputs));
  }, [formInputs]);

  
  // Handle image changes
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleImageChange1 = (e) => {
    const file = e.target.files[0];
    setSelectedImage1(file);
  };

  const handleImageChange2 = (e) => {
    const file = e.target.files[0];
    setSelectedImage2(file);
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  
  const handleShowModall = () => setShowModall(true);
  const handleCloseModall = () => setShowModall(false);


  // Handle gallery button click
  const handleOpenGallery = () => {
    handleShowModal(); // Show the modal
    setSelectedGalleryImage(1); // Set the selected image to Image 1 (you can modify as needed)
  };
  const handleOpenVideos = () => {
    handleShowModall(); // Show the modal
    setSelectedVideos(); // Set the selected image to Image 1 (you can modify as needed)
  };
  // Handle form submission
 
  
//     setAppliedChanges({
//       companyName: formInputs.companyName,
//       name:formInputs.name,
//       designation: formInputs.designation,
//       phoneNumber: formInputs.phoneNumber,
//       email: formInputs.email,
//       address: formInputs.address,
//       image: selectedImage,
//       image1: selectedImage1,
//       image2: selectedImage2,
//       whatsappChat: formInputs.whatsappChat,
//       location: formInputs.location,
//       call: formInputs.call,
//       facebookUrl: formInputs.facebookUrl,
// linkedinUrl: formInputs.linkedinUrl,
// instagramUrl: formInputs.instagramUrl,
// twitterUrl: formInputs.twitterUrl,
// telegramUrl:formInputs.telegramUrl,
// videoUrl1:formInputs.videoUrl1,
// videoUrl2:formInputs.videoUrl2,
//     });
// };

  const handleOpenLocation = () => {
    if (appliedChanges && appliedChanges.location) {
      window.open(appliedChanges.location, '_blank');
    }
  };

  const handleOpenWhatsappChat = () => {
    if (appliedChanges && appliedChanges.whatsappChat) {
      window.open(appliedChanges.whatsappChat, '_blank');
    }
  };
  // const handleOpenVideoUrl1 = () => {
  //   if (appliedChanges && appliedChanges.videoUrl1) {
  //     window.open(appliedChanges.videoUrl1, '_blank');
  //   }
  // };
  // const handleOpenVideosUrl2 = () => {
  //   if (appliedChanges && appliedChanges.videoUrl2) {
  //     window.open(appliedChanges.videoUrl2, '_blank');
  //   }
  // };





  const downloadVCard = () => {
    const vCardData = `
BEGIN:VCARD
VERSION:3.0
TITLE:${appliedChanges.name}
ORG:${appliedChanges.companyName}
TITLE:${appliedChanges.designation}
TEL:${appliedChanges.call}
EMAIL:${appliedChanges.email}
ADR:${appliedChanges.address}
URL:${appliedChanges.websiteUrl}
END:VCARD
    `.trim();

    const blob = new Blob([vCardData], { type: 'text/vcard' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'contact.vcf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

   

  // Function to handle opening the modal
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleOpenModall = () => {
    setShowModall(true);
  };
  // Function to handle closing the modal


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInputs(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAppliedChanges({
      companyName: formInputs.companyName,
      name: formInputs.name,
      designation: formInputs.designation,
      phoneNumber: formInputs.phoneNumber,
      email: formInputs.email,
      address: formInputs.address,
      image: selectedImage,
      image1: selectedImage1,
      image2: selectedImage2,
      whatsappChat: formInputs.whatsappChat,
      location: formInputs.location,
      call: formInputs.call,
      facebookUrl: formInputs.facebookUrl,
      linkedinUrl: formInputs.linkedinUrl,
      instagramUrl: formInputs.instagramUrl,
      twitterUrl: formInputs.twitterUrl,
      telegramUrl: formInputs.telegramUrl,
      videoUrl1: formInputs.videoUrl1,
      videoUrl2: formInputs.videoUrl2,
    });
  };

 

    // try {
    //   // const token = localStorage.getItem('token');
    //    console.log(formInputs);
    //    // Assuming token is used for authentication
    //   const response = await axios.post('http://localhost:3009/submit-form', selectedCard, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //       // 'Authorization': `Bearer ${token}` // If using authentication
    //     }
    //   });

    //   // Handle the response from the API
    //   console.log('Form data submitted successfully', response.data);
    //   console.log(setFormInputs);
    //    setFormInputs({ ...formInputs, websiteUrl: response.data.websiteUrl });
    //   //  const websiteUrl = response.data.formData.websiteUrl;
    //   alert('Form submitted successfully!');

    //   // const websiteUrl = response.data.websiteUrl; // Adjust according to your response structure
    //   // console.log('Website URL:', websiteUrl);

    //   // navigate(`/${websiteUrl}`);
       
    //     console.log(`form/:id`);
    //   localStorage.removeItem('token'); // Remove token if needed

    // } catch (error) {
    //   console.error('Error submitting form data', error);
    //   alert('Error submitting form data');
    // }
    // const handleChange = (e) => {
    //   const { name, value } = e.target;
    //   setFormInputs(prevState => ({...prevState, [name]: value}));
    // };
    // setFormInputs ({
    //   companyName: '',
    // name: '',
    // designation: '',
    // phoneNumber: '',
    // email: '',
    // address: '',
    // location: '',
    // whatsappChat: '',
    // call: '',
    // facebookUrl: '',
    // linkedinUrl: '',
    // instagramUrl: '',
    // twitterUrl: '',
    // telegramUrl: '',
    // image:'',
    // image1: null, 
    // image2: null, 
    // videoUrl1: '',
    // videoUrl2: '',
    // });

  


  return (
    <>
    <Header/>
      <FormContainer color={selectedCard.color}>
        <h2>Card Form</h2>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Company Logo:</Label>
            <Input 
              type="file" 
              accept="image/*" 
              onChange={handleImageChange} 
            />
          </FormGroup>

          <FormGroup>
            <Label>Company Name:</Label>
            <Input 
              type="text" 
              name="companyName" 
              value={formInputs.companyName} 
              onChange={handleChange} 
            />
          </FormGroup>
          
          <FormGroup>
            <Label>Name:</Label>
            <Input 
              type="text" 
              name="name" 
              value={formInputs.name} 
              onChange={handleChange} 
            />
          </FormGroup>

          <FormGroup>
            <Label>Designation:</Label>
            <Input 
              type="text" 
              name="designation" 
              value={formInputs.designation} 
              onChange={handleChange} 
            />
          </FormGroup>

          <FormGroup>
            <Label>Phone Number:</Label>
            <Input 
              type="tel" 
              name="phoneNumber" 
              value={formInputs.phoneNumber} 
              onChange={handleChange} 
            />
          </FormGroup>

          <FormGroup>
            <Label>Email:</Label>
            <Input 
              type="email" 
              name="email" 
              value={formInputs.email} 
              onChange={handleChange} 
            />
          </FormGroup>

          <FormGroup>
            <Label>Address:</Label>
            <TextArea 
              name="address" 
              value={formInputs.address} 
              onChange={handleChange} 
            />
          </FormGroup>

          <FormGroup>
            <Label>Whatsapp Chat Link :    </Label>
            <Input 
              type="text" 
              name="whatsappChat" 
              value={formInputs.whatsappChat} 
              onChange={handleChange} 
            />
          </FormGroup>

          <FormGroup>
            <Label>Location Link:</Label>
            <Input 
              type="text" 
              name="location" 
              value={formInputs.location} 
              onChange={handleChange} 
            />
          </FormGroup>

          <FormGroup>
            <Label>Call Link:</Label>
            <Input 
              type="text" 
              name="call" 
              value={formInputs.call} 
              onChange={handleChange} 
            />
          </FormGroup>

          <FormGroup>
            <Label>Image 1:</Label>
            <Input 
              type="file" 
              accept="image/*" 
              onChange={handleImageChange1} 
            />
          </FormGroup>

          <FormGroup>
            <Label>Image 2:</Label>
            <Input 
              type="file" 
              accept="image/*" 
              onChange={handleImageChange2} 
            />
          </FormGroup>
          <FormGroup>
          <Label for="videoUrl1">Select Video 1:</Label><br />
          <Input
            type="url"
            id="videoUrl1"
            name="videoUrl1"
            value={formInputs.videoUrl1} 
            onChange={handleChange}
          />
        </FormGroup>
        <br />
        <FormGroup>
          <Label for="videoUrl2">Select Video 2:</Label><br />
          <Input
            type="url"
            id="videoUrl2"
            name="videoUrl2"
            value={formInputs.videoUrl2} 
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label >facebookUrl</Label><br />
          <Input
            type="url"
            id="facebookUrl"
            name="facebookUrl"
          
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label >linkedinUrl</Label><br />
          <Input
            type="url"
            id="linkedinUrl"
            name="linkedinUrl"
         
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>instagramUrl</Label><br />
          <Input
            type="url"
            id="instagramUrl"
            name="instagramUrl"
         
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label >twitterUrl</Label><br />
          <Input
            type="url"
            id="twitterUrl"
            name="twitterUrl"
          
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label >telegramUrl</Label><br />
          <Input
            type="url"
            id="telegramUrl"
            name="telegramUrl"
          
            onChange={handleChange}
          />
        </FormGroup>


          <ButtonStyled type="submit">Apply Changes</ButtonStyled>
        </form>
      </FormContainer>

      <FormContainer color={selectedCard.color}>
        {appliedChanges && (
          <div>
            <Row>
              <Col md={6}>
                {appliedChanges.image && (
                  <div>
                    <img src={URL.createObjectURL(appliedChanges.image)} alt="Updated Logo" style={{ width: '250px', height: '250px', borderRadius: '100%' }} />
                  </div>
                )}
              </Col>
              <Col md={6} className='text-left p-5'>
                <p><strong>Company Name:</strong> {appliedChanges.companyName}</p>
                <p><strong>Name:</strong> {appliedChanges.name}</p>
                <p><strong>Designation:</strong> {appliedChanges.designation}</p>
              </Col>
            </Row>

            <Row>
              <Col className='p-4'>
                {appliedChanges.whatsappChat && (
                  <div>
                    <button type="button"  target="_blank" className="btn btn-outline-primary" onClick={handleOpenWhatsappChat}>Whatsapp Chat</button>
                  </div>
                )}
              </Col>
              <Col className='p-4'>
                {appliedChanges.location && (
                  <div>
                    <button type="button"  target="_blank" className="btn btn-outline-primary" onClick={handleOpenLocation}>Open Location</button>
                  </div>
                )}
              </Col>
              <Col className='p-4'>
                {appliedChanges.call && (
                  <div>
                    <a className='text-center' href={`tel:${appliedChanges.call}`}   target="_blank"style={{ textDecoration: 'none' }}>
                      <button className='text-center btn btn-outline-primary'> Call Now</button>
                    </a>
                  </div>
                )}
              </Col>
            </Row>
            <div>
        

      

      {/* Button to open modal */}
      <button className="btn btn-outline-primary m-3" onClick={handleOpenModal}>
        Gallery
      </button>
    


      {/* Render ModalComponent */}
      <ModalComponent
        show={showModal}
        handleClose={handleCloseModal}
        image1={appliedChanges.image1}
        image2={appliedChanges.image2}
      />

     <button className="btn btn-outline-primary m-3"  onClick={handleOpenModall}>
        Videos
      </button>
    <ModalComponents
        showw={showModall}
        handleClosee={handleCloseModall}
        videoUrl1={appliedChanges.videoUrl1}
        videoUrl2={appliedChanges.videoUrl2}
      />

    </div>

            <div className='p-5'>
              <p><strong><IoMdCall /></strong> {appliedChanges.call}</p>
              <p><strong><TfiEmail /></strong> {appliedChanges.email}</p>
              <p><strong><FaLocationDot /></strong> {appliedChanges.address}</p>
            </div>

            <Row>
              <Col>
                <div className=''>
                  <ol>
                    <li><a href={appliedChanges.facebookUrl}  target="_blank"><button style={{borderColor:'white' }}><FaFacebookF   className='icon' style={{ fontSize: '20px' }} /> </button></a></li>
                    <li><a href={appliedChanges.twitterUrl}  target="_blank"><button style={{borderColor:'white' }}><FaTwitter  className='icon' style={{ fontSize: '20px' }} /></button></a></li>
                    <li><a href={appliedChanges.linkedinUrl}  target="_blank"><button  style={{borderColor:'white' }}><FaLinkedinIn  className='icon' style={{ fontSize: '20px' }} /></button></a></li>
                    <li><a href={appliedChanges.instagramUrl}  target="_blank"><button  style={{borderColor:'white' }}><FaInstagram  className='icon' style={{ fontSize: '20px' }} /></button></a></li>
                    <li><a href={appliedChanges.telegramUrl}  target="_blank"><button  style={{borderColor:'white' }}><FaTelegramPlane  className='icon' style={{ fontSize: '20px' }} /></button></a></li>
                  </ol>
                </div>
              </Col>
              <Col className='p-5 m-4'>
                <button type="button" className="btn btn-outline-success" onClick={downloadVCard}>
                  Download vCard
                </button>
                </Col>
                <Col className='p-5 m-3'>
             
             <button type="button" className="btn btn-outline-success" onClick={handleBookCard} >Book This Card</button>  
           
    
                </Col>
               
               
              </Row>
   
          </div>
        )}
      </FormContainer>
 
     <ModalComponent 
        showw={showModal} 
        handleClosee={handleCloseModall} 
        videoUrl1={appliedChanges && appliedChanges.videoUrl1} 
        videoUrl2 ={appliedChanges && appliedChanges.videoUrl2} 
      />
    
      <ModalComponent 
        show={showModal} 
        handleClose={handleCloseModal} 
        image1={appliedChanges && appliedChanges.image1} 
        image2={appliedChanges && appliedChanges.image2} 
      />
      
<br></br>
<Footer />
</>
  
  );
};


export default FormPage;
